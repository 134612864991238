import { ApiGatewayClient } from '../../utils/api-gateway-client.util';
import { ContentTypes } from '../../utils/enums/content-types.enum';
import { HttpMethod } from '../../utils/enums/http-method.enum';
import { ConnectionDataResponse } from '../../utils/interfaces/connection-data-response.interface';
import { config } from '../../config';

/**
 * Class responsible for managing the data requests of the main view
 */
export class ConnectionDataService {
    private client: ApiGatewayClient;
    private headers = {
        'Content-Type': ContentTypes.json,
        Accept: ContentTypes.json,
    };

    /**
     * Instantiates a new identified ApiGatewayClient
     */
    constructor() {
        this.client = new ApiGatewayClient();
    }

    /**
     * This method retrieves the bank connection information stored on a DynamoDB
     * @returns 
     */
    public async getConnectionData(): Promise<ConnectionDataResponse> {
        const url = `${config.connectionBeUrl}/connection-data`;
        const request = {
            url,
            method: HttpMethod.get,
            headers: this.headers,
        };

        const retrievedData = await this.client.sendRequest<ConnectionDataResponse>(
            request,
        );
        const result = retrievedData.data;

        return result;
    }

    /**
     * This method sends the bank connection information to a DynamoDB
     * @param itemId id of the element to update
     * @param body connection information to update
     * @returns 
     */
    public async updateConnectionData(itemId: string, body: string): Promise<object> {
        const url = `${config.connectionBeUrl}/item/${itemId}`;

        const response = await this.client.postRequest(url, body);
        return response;
    }
}
