import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ConnectionData } from './views/ConnectionData';
import { ErrorPage } from './views/ErrorPage';

const App = () => (
  <Router>
    <Switch>
      <Route path='/failed-request' component={ErrorPage} />
      <Route path='/' component={ConnectionData}/>
    </Switch>
  </Router>
);

export default App;
