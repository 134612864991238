import { Redirect } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ConnectionDataService } from './connectionData.service';
import { ConnectionDataResponse } from '../../utils/interfaces/connection-data-response.interface';
import { DateUtils } from '../../utils/dates.util';

export const ConnectionData: React.FC = () => {
    const service = useRef(new ConnectionDataService()).current;
    const dateUtils = new DateUtils();
    const [error, setError] = useState(false);
    const [ready, setReady] = useState(false);
    const [bankData, setBankData] = useState<ConnectionDataResponse>();
    const [state, setState] = useState('all');
    const [currentIndex, setIndex] = useState(0);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        service
            .getConnectionData()
            .then((response) => {
                setBankData(response);
                setReady(true);
            })
            .catch(() => {
                setError(true);
            });
    }, [service]);

    useEffect(() => {
        if (reset)
        {
            const body = `{"banco": "${bankData?.info[currentIndex].banco}", "enabled": "1", "isConnected": "1", "showTable": "1", "periodos": [{"inicio": ${dateUtils.getMxDate('', -1)}, "fin": ${dateUtils.getMxDate('', 0)}, "flag_conexion": "0"}]}`;
            service
                .updateConnectionData(bankData?.info[currentIndex].id || '', body)
                .then(() => {
                    window.location.reload();
                });
            
            setReset(false);
        }
    }, [reset]);

    const renderTableRow = () => {
        return bankData?.info.map((data, index) => {
            const id = `tr_${index}`;
            const canvasId = `canvas_${index}`;
            return (
                <tr key={index} id={id} style={{ height: 0 }}>
                    <td style={{ height: 0 }} onClick={() => {
                        setIndex(index);
                        setState('details');
                    }
                    }>{data.banco}</td>
                    <td style={{ height: 25 }}>
                        <canvas id={canvasId} style={{ width: 845, height: 25 }}></canvas>
                    </td>
                </tr>
            );
        });
    };

    const showDetails = () => {
        return (
            <tr key={0} id={'tr_0'} style={{ height: 0 }}>
                <td style={{ height: 0 }}>{bankData?.info[currentIndex].banco}</td>
                <td style={{ height: 25 }}>
                    <canvas id={'canvas_0'} style={{ width: 845, height: 25 }}></canvas>
                </td>
            </tr>
        );
    };

    const renderControls = () => {
        return (
            <div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
                <button onClick={() => changeStatus(bankData?.info[currentIndex])}>{bankData?.info[currentIndex].isConnected === '1' ? 'desconectar' : 'conectar'}</button>
                <button onClick={() => changeDay(bankData?.info[currentIndex])}>Cambio de dia</button>
                <button onClick={() => setReset(true)}>Reset</button>
                <button onClick={() => changeTableStatus(bankData?.info[currentIndex])}>{bankData?.info[currentIndex].showTable === '1' ? 'ocultar tabla' : 'mostrar tabla'}</button>
            </div>
        );
    };

    const renderDetails = (entry) => {
        const length = entry.periodos.length;
        let leyendaSistema;
        const uc = (length > 0) ? new Date(entry.periodos[length - 1].inicio) : null;
        const rows: object[] = [];

        for (let i = 0; i < length; i++) {
            const flagConexion = entry.periodos[i].flag_conexion;

            if (flagConexion === '1') {
                leyendaSistema = 'COAS';
                rows.push(
                    <tr key={i}>
                        <td>{formatHora(new Date(entry.periodos[i].inicio))}</td>
                        <td>{formatHora(new Date(i === 0 && entry.isConnected === '1' ? 
                                dateUtils.getMxDate('', 0) :
                                entry.periodos[i].fin
                                ))}
                        </td>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>&nbsp; {leyendaSistema}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                );
            }

            else {
                leyendaSistema = 'APLICATIVO SPEI';
                rows.push(
                    <tr key={i}>
                        <td>{formatHora(new Date(entry.periodos[i].inicio))}</td>
                        <td>{formatHora(new Date(i === 0 && entry.isConnected === '1' ? 
                                dateUtils.getMxDate('', 0) :
                                entry.periodos[i].fin
                                ))}
                        </td>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>&nbsp; {leyendaSistema}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                );
            }

        }

        return (
            <div className={'detalleConexiones'}>
                <div>
                    Institución: {entry.banco}
                    <br />
                    Última conexión: {(uc != null) ? formatHora(uc) : 'Sin conexión'}
                    <br />
                    Cantidad de conexiones: {length}
                </div>
                <div>
                    <table className='styled-table'>
                        <thead>
                            <tr>
                                <th>Inicio</th>
                                <th>Fin</th>
                                <th>Sistema</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const formatHora = (d) => {
        const dd = d.getDate(), mm = d.getMonth() + 1, y = d.getFullYear();
        const h = d.getHours(), m = d.getMinutes(), s = d.getSeconds();
        const cad = (dd < 10 ? '0' : '') + dd + '-' + (mm < 10 ? '0' : '') + mm + '-' + y;
        return ((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s) + '   ' + cad;
    };

    const changeStatus = (entry) => {
        let periodos = '';
        let isConnected;
        if (entry.isConnected === '1') {
            periodos = `[{"inicio": ${entry.periodos[0].inicio}, "fin": ${dateUtils.getMxDate('', 0)}, "flag_conexion": "0"},`;
            isConnected = 0;
            for (let i = 1; i < entry.periodos.length; i++) {
                periodos += `{"inicio": ${entry.periodos[i].inicio}, "fin": ${entry.periodos[i].fin}, "flag_conexion": "0"},`;
            }
        }
        else {
            periodos = `[{"inicio": ${dateUtils.getMxDate('', 0)}, "fin": ${dateUtils.getMxDate('', 0)}, "flag_conexion": "0"},`;
            isConnected = 1;
            for (const periodo of entry.periodos) {
                periodos += `{"inicio": ${periodo.inicio}, "fin": ${periodo.fin}, "flag_conexion": "0"},`;
            }
        }

        periodos = periodos.slice(0, -1);
        periodos += ']';
        const body = `{"banco": "${entry.banco}", "enabled": "1", "isConnected": "${isConnected}", "showTable": "${entry.showTable}", "periodos": ${periodos}}`;
        service
            .updateConnectionData(entry.id || '', body)
            .then(() => {
                window.location.reload();
            });
    };

    const changeTableStatus = (entry) => {
        const showTable = entry.showTable === '1' ? 0 : 1;
        let periodos = '[';

        for (const periodo of entry.periodos) {
            periodos += `{"inicio": ${periodo.inicio}, "fin": ${periodo.fin}, "flag_conexion": "0"},`;
        }

        periodos = periodos.slice(0, -1);
        periodos += ']';
        const body = `{"banco": "${entry.banco}", "enabled": "1", "isConnected": "${entry.isConnected}", "showTable": "${showTable}", "periodos": ${periodos}}`;
        service
            .updateConnectionData(entry.id || '', body)
            .then(() => {
                window.location.reload();
            });
    };

    const changeDay = (entry) => {
        const body = `{"banco": "${entry.banco}", "enabled": "1", "isConnected": "1", "showTable": "${entry.showTable}", "periodos": [{"inicio": ${dateUtils.getMxDate(entry.periodos[0].inicio, 1)}, "fin": ${dateUtils.getMxDate('', 0)}, "flag_conexion": "0"}]}`;
        service
            .updateConnectionData(entry.id || '', body)
            .then(() => {
                window.location.reload();
            });
    };

    return error ? (
        <Redirect to='failed-request' />
    ) : ready ? (
        <div>
            {state === 'details' && renderControls()}
            <div id='header'>
                <div id='headermobile'></div>
            </div>
            <div id='appHead'>
                <span className='appName'>Sandbox</span>
            </div>
            <div id='appMain'>
                <div className='header'>
                    <div className='mensaje'>Seleccione la instituci&oacute;n para mostrar el detalle.</div>
                    <div id='divFechaOperacion'>
                        Fecha de operaci&oacute;n
                        <select id='sFechaOperacion'>

                            <option value='20220221'>2022/02/21</option>

                        </select>
                    </div>
                </div>
                <table className='timeChart' cellPadding='0' cellSpacing='0'>
                    <thead>
                        <tr><td className='institucionSeleccionada'><div id='btnTodos' className='' onClick={() => setState('all')}>TODOS</div></td><td><canvas id='timeLine' width='854px' height='45px'></canvas></td></tr>
                    </thead>
                    <tbody>
                        {state === 'all' && renderTableRow()}
                        {state === 'details' && showDetails()}
                    </tbody>
                    <tfoot>
                        <tr><td colSpan={2}></td></tr>
                    </tfoot>
                </table>
                {state === 'details' && bankData?.info[currentIndex].showTable === '1' && renderDetails(bankData?.info[currentIndex])}
            </div>
        </div >
    ) : (
        <div>
            Cargando....
        </div>
    );
};
